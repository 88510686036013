.playerRow {
  &__container {
    padding: 8px;
    border: 1px solid;
    border-radius: 4px;
    font-size: 20px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
  &__waitingLoader {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }
  &__kickOut {
    height: 20px;
    width: 20px;
    margin-right: 12px;
    &:hover {
      cursor: pointer;
    }
    &__self {
      opacity: 0.1;
      pointer-events: none;
    }
  }
  &__name {
    display: flex;
    &--offline {
      display: flex;
      opacity: 0.2;
    }
  }
  &__adminChip {
    margin-left: 16px;
    font-size: 14px;
    border: 1px solid #eeeaea;
    border-radius: 16px;
    background-color: #eeeaea;
    color: #4a4a4a;
    padding: 4px 12px;
    align-self: center;
  }
}
