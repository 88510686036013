.game {
  &__container {
    display: flex;
    &__board {
      display: flex;
    }
    &__panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;
    }
    &__scoreTurn {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      gap: 50px;
    }
    &__deck {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 100px;
    }
  }
  &__title {
    font-size: 48px;
  }
  &__bottom {
    display: flex;
    width: 100%;
  }
}
