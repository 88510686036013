.board {
  &__container {
    display: grid;
    grid-template-columns: repeat(10, auto);
    justify-content: start;
    align-items: start;
    border: 2px solid;
    border-radius: 8px;
    padding: 4px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      position: relative;
      &--hover {
        @extend .board__container__cell;
        cursor: pointer;
      }
    }
    &__coin {
      position: absolute;
    }
  }
}
