.hand {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex-grow: 90;
  &__container {
    display: flex;
    margin-left: 100px;
    align-self: center;
  }
  &__card {
    transform-origin: 25% 100%;
    transition: transform-origin 0.3s linear;
    margin-left: -120px;
    &:hover {
      cursor: pointer;
    }
    &--turn {
      @extend .hand__card;
      transform-origin: 50% 250%;
    }
  }
}
