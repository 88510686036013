.button {
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 4px;
  font-family: inherit;
  letter-spacing: 1.5px;
  &:hover {
    background-color: #fff;
  }
  &:active {
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
  }
  &__large {
    font-size: 32px;
    padding: 8px 28px;
    width: 220px;
  }
  &__medium {
    font-size: 24px;
    width: 180px;
  }
  &__small {
    font-size: 20px;
    width: 140px;
  }
  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
