.deck {
  &__container {
    margin: 16px;
    padding-bottom: 8px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    border-radius: 8px;
    &__pile {
      position: relative;
      width: 140px;
      height: 180px;
      border-radius: 8px;
    }
  }
  &__img {
    position: absolute;
    width: 100px;
    top: 14px;
    left: 12px;
    transition: transform 0.3s ease, z-index 0.3s ease;
    &:hover {
      transform: scale(1.1);
      z-index: 100;
      cursor: pointer;
    }
  }
  &__title {
    font-size: 20px;
  }
}
