.score {
  &__container {
    border: 2px solid;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__heading {
    font-size: 28px;
    font-weight: 500;
    padding: 4px;
    &--green {
      @extend .score__heading;
      color: green;
    }
    &--red {
      @extend .score__heading;
      color: red;
    }
    &--blue {
      @extend .score__heading;
      color: blue;
    }
  }
  &__score {
    font-size: 24px;
    text-align: center;
  }
  &__title {
    font-size: 22px;
  }
}
