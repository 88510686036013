.lastCardPlayed {
  &__container {
    margin: 16px;
    padding-bottom: 8px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    border-radius: 8px;
    &__card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 180px;
      border-radius: 8px;
    }
  }
}
